import React from 'react';

import { Tag } from '../Tag/Tag';
import {
  Icon, TriggerButton, TriggerButtonProps, ComponentProps,
} from '@Components';

export interface UnfilterTagProps extends ComponentProps {
  disabled?: TriggerButtonProps['disabled'];
  eventLabel?: TriggerButtonProps['eventLabel'];
  onTrigger: TriggerButtonProps['onTrigger'];
  trackingAction?: TriggerButtonProps['trackingAction'];
  ariaLabel?: string;
}

export const UnfilterTag: React.FC<React.PropsWithChildren<UnfilterTagProps>> = ({
  children,
  'data-id': dataId = 'unfilter-tag',
  disabled = false,
  eventLabel,
  onTrigger,
  trackingAction,
  ariaLabel,
  className,
}) => (
  <Tag
    className={className}
    data-id={dataId}
    sx={disabled ? {
      '&,&:hover': {
        backgroundColor: 'tagprimaryDisabledlight',
        color: 'textDisabled',
      },
    } : {}}
  >
    <div
      sx={{
        display: 'inline-block',
        verticalAlign: 'middle',
      }}
    >
      { children }
    </div>
    <TriggerButton
      data-id="unfilter-tag-btn"
      aria-label={ariaLabel}
      disabled={disabled}
      eventLabel={eventLabel}
      onTrigger={onTrigger}
      sx={{
        marginLeft: '3xs',
        '&:hover > svg, &:focus > svg, &:active > svg': {
          opacity: 1,
        },
      }}
      trackingAction={trackingAction}
    >
      <Icon
        name="Actions/Delete"
        size="16"
        sx={{
          color: disabled ? 'iconDisabled' : 'tagprimaryText',
          opacity: disabled ? undefined : 0.65,
          verticalAlign: 'middle',
        }}
      />
    </TriggerButton>
  </Tag>
);
