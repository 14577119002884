import React, { useRef, useState } from 'react';

import { getModalSectionPositionStyles, ModalComponentOrElement, createModalElement } from './utils';
import { ComponentProps, Portal } from '@Components';
import { useBodyScrollLockCallbackRef, useClickOutside, useOnKeyEvent } from '@Hooks';
import { Key } from '@Key';
import {
  fadeIn, fadeOut, IncrementSizes, motion,
} from '@Tokens';

type WidthValue = keyof Pick<IncrementSizes, 'sidebarwidth' | '420' | 'formsmaxwidth' | 'copymaxwidth' | 'fullcontentmaxwidth'>;

export interface ModalModalProps extends Pick<ComponentProps, 'className' | 'data-id'> {
  show: boolean;
  onClose: () => void;
  /**
    * Wrap the content using React.Fragment
    */
  Actions?: ModalComponentOrElement;
  Header?: ModalComponentOrElement;
  Content: ModalComponentOrElement;
  /**
   * - `False`: on mobile the modal takes up the full view. This is the safe option.
   * - `True`: on mobile the modal floats above the page. Only use if the modal content is not going
   * to take up the full mobile height.
   */
  mobileFloating?: boolean;
  /**
   * - `False`: on mobile the modal floats and sticks to the bottom (the default behavior).
   * - `True`: on mobile the modal is vertically centered within the viewport. This works only when `mobileFloating` is `true`.
   */
  mobileVerticalCentering?: boolean;
  fullWidthActions?: boolean;
  /**
   * - 'True': all paddings set to 0
   * - 'False'/undefined: no change on padding
   */
  noPadding?: boolean;
  /**
   * - 'False': the border between modal content and footer elements will be displayed (the default behavior)
   * - 'True': the border between modal content and footer elements will be removed
   */
  noFooterBorder?: boolean;
  /**
   * If width is a single value, it sets the width for desktop devices, while the mobile width is automatically set to full width. (the default value is 'formsmaxwidth')
   * If width is an array, the first element represents the width for mobile devices, and the second element represents the width for desktop devices.
   */
  width?: WidthValue | [ WidthValue, WidthValue ];
  /**
   * - 'False': we have default width and height, or which we set throught properties.
   * - 'True': width and height 100% of the screen and we don't have a rounded border.
   */
  isFullScreen?: boolean;
  /**
   * Callback for when the modal has finished animating in or out.
   * @param wasWanted
   */
  onAnimationEnd?: (wasWanted: boolean) => void;
}

interface ModalContainerProps extends Omit<ModalModalProps, 'show'> {
  onAnimationEnd: (p: boolean) => void;
  isWanted: boolean;
}

const ModalContainer: React.FC<React.PropsWithChildren<ModalContainerProps>> = ({
  onClose,
  Actions,
  onAnimationEnd,
  isWanted,
  mobileFloating = false,
  mobileVerticalCentering = false,
  'data-id': dataId,
  className,
  Header,
  Content,
  fullWidthActions = false,
  noPadding = false,
  noFooterBorder = false,
  width = 'formsmaxwidth',
  isFullScreen = false,
}) => {
  const ref = useRef(null);

  const overlayRef = useRef(null);
  const setRef = useBodyScrollLockCallbackRef(overlayRef);
  const shouldCenterVerticallyMobile = mobileFloating && mobileVerticalCentering;

  useClickOutside({
    ref,
    withinRef: overlayRef,
    isActive: true,
    onClick: onClose,
  });
  useOnKeyEvent(Key.Escape, onClose);

  const actions = createModalElement(Actions, onClose);
  const header = createModalElement(Header, onClose);
  const content = createModalElement(Content, onClose);

  return (
    <div
      onAnimationEnd={() => onAnimationEnd(isWanted)}
      sx={{
        animation: `forwards ${isWanted ? fadeIn : fadeOut} ${motion.fadeinoutCheetah.duration}ms ${motion.fadeinoutCheetah.easing}`,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 'modal',
        backgroundColor: mobileFloating ? 'modalOverlay' : [ null, 'modalOverlay' ],
      }}
      ref={setRef}
    >
      <section
        role="dialog"
        data-id={dataId}
        data-disable-click-outside-container
        className={className}
        ref={ref}
        sx={{
          transform: [ shouldCenterVerticallyMobile ? 'translateY(-50%)' : null, 'translateX(-50%) translateY(-50%)' ],
          width: Array.isArray(width) ? width : [ null, width ],
          ...getModalSectionPositionStyles({ mobileFloating, shouldCenterVerticallyMobile }),
          height: mobileFloating ? null : [ '100%', 'auto' ],
          backgroundColor: 'backgroundWhite',
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: [ '100%', '70%' ],
          marginLeft: shouldCenterVerticallyMobile ? [ 'xs', 0 ] : 0,
          marginRight: shouldCenterVerticallyMobile ? [ 'xs', 0 ] : 0,
          borderTopLeftRadius: [ mobileFloating ? '12' : null, '12' ],
          borderTopRightRadius: [ mobileFloating ? '12' : null, '12' ],
          borderBottomLeftRadius: [ shouldCenterVerticallyMobile ? '12' : null, '12' ],
          borderBottomRightRadius: [ shouldCenterVerticallyMobile ? '12' : null, '12' ],
          overflow: 'hidden',
          ...(isFullScreen && {
            width: '100vw',
            height: '100%',
            maxHeight: '100%',
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
            borderBottomLeftRadius: '0',
            borderBottomRightRadius: '0',
          }),
        }}
      >
        {header}
        <div
          sx={{
            overflow: 'auto',
            paddingX: 'xs',
            flexGrow: 1,
            paddingBottom: actions ? null : 'xl',
            padding: noPadding && 0,
          }}
        >
          {content}
        </div>
        {actions && (
          <div
            sx={{
              borderTopWidth: noFooterBorder ? null : 'outlinedStrokeWeight',
              borderTopColor: noFooterBorder ? null : 'strokeLightneutral',
              borderTopStyle: noFooterBorder ? null : 'solid',
              display: 'grid',
              gridAutoColumns: [ '1fr', 'auto' ],
              gridAutoFlow: 'column',
              gap: '2xs',
              paddingY: '2xs',
              paddingX: 'xs',
              justifyContent: [ 'stretch', fullWidthActions ? null : 'end' ],
            }}
          >
            {actions}
          </div>
        )}
      </section>
    </div>
  );
};

/**
 * Modal component to show information above the rest of the page.
 *
 * Unlike Tooltip and Popover it is not visually connected to its trigger (which is why there is no
 * need for a hook version).
 *
 * It is made up of 3 bits of content:
 * * `Header`
 * * `Content`
 * * `Actions`
 *
 * `Header` should normally use either a `ModalHeader` or `ModalHeaderBig` component.
 * `Actions` should normally be one or two `Button`s contained in a `Fragment`.
 *
 *
 * Use `show` to hide and show rather than conditionally rendering `Modal`, so that it can animate.
 */
export const Modal: React.FC<React.PropsWithChildren<ModalModalProps>> = ({
  'data-id': dataId,
  show: isWanted,
  onAnimationEnd,
  ...rest
}) => {
  // Record the old state of show, so we can animate between states
  const [ wasWanted, setWasWanted ] = useState(false);

  const handleAnimationEnd = (p: boolean) => {
    setWasWanted(p);
    onAnimationEnd?.(p);
  };

  if (!wasWanted && !isWanted) {
    return null;
  }

  return (
    <Portal>
      <ModalContainer
        data-id={dataId}
        isWanted={isWanted}
        onAnimationEnd={handleAnimationEnd}
        {...rest}
      />
    </Portal>
  );
};
