import { useState, useCallback } from 'react';

import { useOnKeyEvent } from '@Hooks';
import { Key } from '@Key';

type ModalEvent = Pick<Event, 'preventDefault' | 'stopPropagation'>;

export interface ModalHook<E extends ModalEvent> {
  /**
   * modal open state
   * */
  isOpen: boolean;
  /**
   *  callback to open modal
   *  */
  setOpen: (event?: E) => void;
  /**
   * callback to close modal
   * */
  setClosed: (event?: E) => void;
}

export const useModal = <E extends ModalEvent>(
  /**
   * initial modal state
   * */
  initialOpenState: boolean = false,
  /**
   *  Modal will close on ESC keypress by default.
   * `preventEsc` - to prevent default behavior
   * */
  preventEsc?: boolean,
): ModalHook<E> => {
  const [ isOpen, setIsOpen ] = useState(initialOpenState);

  useOnKeyEvent(
    Key.Escape,
    () => {
      if (isOpen && !preventEsc) {
        setIsOpen(false);
      }
    },
    [ isOpen ],
  );

  const setOpen = useCallback((event?: E) => {
    if (event) {
      event.preventDefault();
    }
    setIsOpen(true);
  }, []);

  const setClosed = useCallback((event?: E) => {
    if (event) {
      event.preventDefault();
    }
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    setOpen,
    setClosed,
  };
};
