import React from 'react';
import { SxStyleProp } from 'theme-ui';

import {
  ComponentProps,
  Icon,
  IconProps,
  Label,
} from '@Components';

export const badgeUSPCategories = [ 'ATOLProtected', 'FreeHolidayChanges', 'FreeHotelAmendments', 'RefundGuarantee', 'CustomerSupport', 'PayMonthly', 'EnhancedHotelStandards', 'PriceMatchGuarantee' ];
export type BadgeUSPCategory = typeof badgeUSPCategories[number];

const badgeUSPConfigs: Record<BadgeUSPCategory, BadgeUSPConfig> = {
  ATOLProtected: {
    variantStyles: {
      backgroundColor: '#fff',
      borderColor: 'strokeOutlinedark',
    },
    text: 'ATOL Protected',
    icon: 'Content/USPATOL',
  },
  FreeHolidayChanges: {
    variantStyles: {
      backgroundColor: 'backgroundSuccesslight',
      borderColor: 'strokeSuccessdark',
    },
    text: 'Free holiday changes',
    icon: 'Content/USPFlexibility',
  },
  FreeHotelAmendments: {
    variantStyles: {
      backgroundColor: 'backgroundSuccesslight',
      borderColor: 'strokeSuccessdark',
    },
    text: 'Free hotel amendments',
    icon: 'Content/USPFlexibility',
  },
  RefundGuarantee: {
    variantStyles: {
      backgroundColor: 'backgroundSuccesslight',
      borderColor: 'strokeSuccessdark',
    },

    text: 'Refund Guarantee',
    icon: 'Content/USPRefunds',
  },
  CustomerSupport: {
    variantStyles: {
      backgroundColor: 'backgroundSuccesslight',
      borderColor: 'strokeSuccessdark',
    },
    text: 'Customer support',
    icon: 'Content/USPSupport',
  },
  PayMonthly: {
    variantStyles: {
      backgroundColor: 'backgroundSuccesslight',
      borderColor: 'strokeSuccessdark',
    },
    text: 'Pay monthly',
    icon: 'Content/USPPayMonthlyPound',
  },
  EnhancedHotelStandards: {
    variantStyles: {
      backgroundColor: 'backgroundSuccesslight',
      borderColor: 'strokeSuccessdark',
    },
    text: 'Enhanced hotel standards',
    icon: 'Content/USPStandards',
  },
  PriceMatchGuarantee: {
    variantStyles: {
      backgroundColor: 'backgroundSuccesslight',
      borderColor: 'strokeSuccessdark',
    },
    text: 'Price match Guarantee',
    icon: 'Content/USPPriceMatch',
  },
};
export interface BadgeUSPProps extends ComponentProps {
  category: BadgeUSPCategory;
}
export interface BadgeUSPConfig {
  variantStyles?: SxStyleProp;
  icon: IconProps['name'];
  text: string;
}

export const BadgeUSP: React.FC<React.PropsWithChildren<BadgeUSPProps>> = ({
  className,
  'data-id': dataId,
  children,
  category,
}) => {
  const config = badgeUSPConfigs[category];

  return (
    <Label
      className={className}
      data-id={dataId}
      sx={{
        whiteSpace: 'nowrap',
        paddingX: '3xs',
        borderRadius: 'rounded',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '4xs',
        borderWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        minHeight: '24px',
        ...config.variantStyles,
      }}
      variant="small"
    >
      {config.icon && (
        <Icon
          size="16"
          name={config.icon}
        />
      )}

      {children ?? config.text}
    </Label>
  );
};
