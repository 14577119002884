import React from 'react';

import { PillSelector } from '../PillSelector';

interface PageButtonProps {
  pageNumber: number;
  isCurrent: boolean;
  onClick: (pageNumber: number) => void;
}

export const PageButton = (
  { pageNumber, onClick, isCurrent }: React.PropsWithChildren<PageButtonProps>,
) => (
  <li
    sx={{
      marginRight: '3xs',
      minWidth: '36px',
      alignItems: 'center',
      textAlign: 'center',
      display: 'flex',
    }}
  >
    <PillSelector
      stretch
      onTrigger={() => onClick(pageNumber)}
      selected={isCurrent}
      sx={{
        '> *:first-of-type': {
          lineHeight: '18px',
        },
      }}
    >
      {String(pageNumber + 1)}
    </PillSelector>
  </li>
);
