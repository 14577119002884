import React, { ReactElement } from 'react';

import {
  ActionButton, ComponentProps, ellipsis, getActionButtonIconVariationWidth, Label,
} from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';

export interface ModalHeaderProps extends ComponentProps {
  label?: ReactElement | string;
  position?: 'center' | 'left';
  onClose: () => void;
  onBack?: () => void;
  layout: 'small' | 'medium';
}

export const ModalHeader: React.FC<React.PropsWithChildren<ModalHeaderProps>> = ({
  label,
  position = 'left',
  onClose,
  onBack,
  layout,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <header
      sx={{
        display: 'flex',
        paddingX: 'xs',
        paddingY: '3xs',
        gap: (theme) => theme.space.l,
        alignItems: 'center',
        backgroundColor: 'backgroundWhite',
        height: 56,
      }}
      className={className}
    >
      {onBack
        && (
          <ActionButton
            icon="Navigation/ArrowBack"
            type="Icon"
            onClick={onBack}
            ariaLabel={t('back')}
            sx={{ backgroundColor: 'transparent' }}
          />
        )}
      <Label
        variant={layout === 'small' ? 'smallbold' : 'largebold'}
        sx={{
          ...ellipsis,
          overflowY: 'hidden',
          flexGrow: 1,
          textAlign: position === 'center' ? 'center' : null,
          marginLeft: (theme) => (!onBack && position === 'center'
            ? getActionButtonIconVariationWidth(theme) + theme.space.l
            : null),
        }}
      >
        {label}
      </Label>
      <ActionButton
        icon="Navigation/Close"
        type="Icon"
        onClick={onClose}
        data-id="modal-close-btn"
        ariaLabel={t('close')}
        sx={{ backgroundColor: 'transparent' }}
      />
    </header>
  );
};
