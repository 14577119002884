import React from 'react';

import { ComponentProps } from '@Components';
import { ThemeDefinition } from '@Themes';
import { LabelVariants } from '@Tokens';
import { Responsive } from '@Utils';

export interface LabelProps extends ComponentProps {
  as?: 'span' | 'p' | 'label';

  /**
   * The Label variant.
   */
  variant: Responsive<LabelVariants>;
}

export const Label: React.FC<React.PropsWithChildren<LabelProps>> = ({
  as = 'span',
  variant,
  ...props
}) => {
  const Wrapper = as;

  return (
    <Wrapper
      sx={(theme: ThemeDefinition) => (Array.isArray(variant)
        ? { '&': variant.map((v) => theme.typography.label[v]) }
        : theme.typography.label[variant])}
      {...props}
    />
  );
};
