import { type SashVariant } from '@loveholidays/design-tokens';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { Label, ComponentProps } from '@Components';

export { type SashVariant, sashVariants } from '@loveholidays/design-tokens';

export const sashVariantStyles = {
  Couples: {
    backgroundColor: '#fe9dd1',
    color: 'textDefault',
  },
  Family: {
    backgroundColor: '#b4bbff',
    color: 'textDefault',
  },
  Location: {
    backgroundColor: '#ffe68d',
    color: 'textDefault',
  },
  Luxury: {
    backgroundColor: '#d2aaf5',
    color: 'textDefault',
  },
  Promotion: {
    backgroundColor: '#ff5c5c',
    color: 'textLight',
  },
  Subtle: {
    color: 'textDefault',
  },
  Value: {
    backgroundColor: '#ffab91',
    color: 'textDefault',
  },
  Wellness: {
    backgroundColor: '#88e3d3',
    color: 'textDefault',
  },
};

export type ExtendedSashVariant = SashVariant | 'Black Friday' | 'Cyber Monday';

const variantStyles: Record<ExtendedSashVariant, Partial<SxStyleProp>> = {
  ...sashVariantStyles,
  'Black Friday': {
    backgroundColor: 'backgroundBlack',
    color: 'iconTrustpilot3star',
  },
  'Cyber Monday': {
    backgroundColor: 'backgroundBlack',
    color: 'iconTrustpilot3star',
  },
};

export interface SashProps extends Pick<ComponentProps, 'className' | 'data-id'> {
  /**
   * The color variant
   */
  variant: ExtendedSashVariant;
  children?: string;
}

const sashStyle: SxStyleProp = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '28',
  paddingX: 'xs',
  borderRadius: '12',
};

export const Sash: React.FC<React.PropsWithChildren<SashProps>> = ({
  variant,
  children,
  'data-id': dataId,
  className,
}) => (
  <div
    sx={{
      ...sashStyle,
      ...variantStyles[variant] as SxStyleProp,
    }}
    className={className}
    data-id={dataId}
  >
    <Label
      variant="smallbold"
    >
      {children}
    </Label>
  </div>
);
