import React, { ComponentProps as GetComponentProps } from 'react';
import { SxStyleProp } from 'theme-ui';

import { Label, Link, TriggerButton } from '@Components';
import type { TriggerButtonProps, ComponentProps } from '@Components';

export type PillSelectorProps = Omit<ComponentProps, 'as'> &
(
  | {
    onTrigger: TriggerButtonProps['onTrigger'];
    as?: TriggerButtonProps['as'];
  }
  | ({
    as: 'a';
  } & GetComponentProps<typeof Link>)
) & {
  selected?: boolean;
  disabled?: TriggerButtonProps['disabled'];
  /**
   * Whether it should extend to 100% width of parent, default to false
   */
  stretch?: boolean;
};

const getTextColor = (props: PillSelectorProps) => {
  if (props.disabled) {
    return 'textDisabled';
  }

  if (props.selected) {
    return 'textLight';
  }

  return 'textDefault';
};

export const PillSelector: React.FC<React.PropsWithChildren<PillSelectorProps>> = ({
  children,
  ...props
}) => {
  const commonStyles: SxStyleProp = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingY: '3xs',
    paddingX: '2xs',
    border: 'solid',
    borderWidth: 'outlinedStrokeWeight',
    borderRadius: 'rounded',
    borderColor: !props.disabled ? 'strokeDarkneutral' : 'strokeDisabledlight',
    color: getTextColor(props),
    ...(props.stretch && { width: '100%' }),
    ...(props.selected && {
      backgroundColor: 'backgroundBlack',
      borderColor: 'backgroundBlack',
    }),
    '&:hover, &:focus': {
      ...(props.disabled && {
        cursor: 'not-allowed',
      }),
      color: getTextColor(props),
      ...(!props.selected && !props.disabled && {
        backgroundColor: 'backgroundPrimarylight',
        borderColor: 'backgroundPrimarylight',
      }),
    },
    ':visited': {
      color: getTextColor(props),
    },
  };

  const content = (
    <Label
      variant="small"
      sx={{ whiteSpace: 'nowrap' }}
    >
      {children}
    </Label>
  );

  if (props.as === 'a') {
    return (
      <Link
        sx={{
          ...commonStyles,
          textDecoration: 'none',
        }}
        {...props}
      >
        {content}
      </Link>
    );
  }

  const { as, ...restProps } = props;

  return (
    <TriggerButton
      as={as}
      sx={commonStyles}
      {...restProps}
    >
      {content}
    </TriggerButton>
  );
};

