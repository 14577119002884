import { IconProps } from 'components/Icon';
import React from 'react';

import { ComponentProps, Icon } from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';
import { Colors } from '@Tokens';

const TOTAL_RATING = 5;

const EMPTY_BACKGROUND_COLOR: keyof Colors = 'iconTrustpilot0star';

// Currently we don't support a rating below 4
const ratingToColor: Record<TrustpilotRatingProps['rating'], keyof Colors> = {
  4: 'iconTrustpilot4star',
  4.5: 'iconTrustpilot5star',
  5: 'iconTrustpilot5star',
} as const;

const sizes: Record<TrustpilotRatingProps['size'], { iconSize: IconProps['size'] }> = {
  24: { iconSize: '16' },
  36: { iconSize: '24' },
};

export interface TrustpilotRatingProps extends ComponentProps {
  rating: number;
  size?: 24 | 36;
}

export const TrustpilotRating: React.FC<React.PropsWithChildren<TrustpilotRatingProps>> = ({
  rating,
  'data-id': dataId = 'trustpilot-rating',
  className,
  size = 24,
}) => {
  const { t } = useTranslation();

  return (
    <span
      className={className}
      data-id={dataId}
      aria-label={t('brandReviewRating', { brand: 'Trustpilot', rating })}
      role="img"
      sx={{
        display: 'flex',
        gap: '4xs',
        alignItems: 'center',
      }}
    >
      {[ ...Array(TOTAL_RATING) ].map((_, idx) => (
        <span
          key={idx}
          sx={{
            height: size,
            width: size,
            background: ({ colors }) => {
              if (idx + 0.5 === rating) {
                return `linear-gradient(90deg, ${colors[ratingToColor[rating]]} 50%, ${colors[EMPTY_BACKGROUND_COLOR]} 50%)`;
              } if (idx >= rating) {
                return colors[EMPTY_BACKGROUND_COLOR];
              }

              return colors[ratingToColor[rating]];
            },
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon
            name="Brands/Trustpilot"
            size={sizes[size].iconSize}
            color="backgroundWhite"
          />
        </span>
      ))}
    </span>
  );
};
