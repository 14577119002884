import React, { PropsWithChildren } from 'react';

import { TabList } from './TabList';
import { TabPanel } from './TabPanel';
import { TabsContext } from './TabsContext';
import { TabChildren, TabsProps } from './types';

type ControlledTabsProps = PropsWithChildren<Omit<TabsProps, 'initialTabId' | 'value'> & {
  activeTabId: string;
  className?: string;
  tabs: TabChildren;
}>;

export const ControlledTabs = (props: ControlledTabsProps) => {
  const {
    activeTabId,
    prefix,
    className,
    tabListStyles,
    tabPanelStyles,
    tabs,
    onTabChanged = () => {},
    indentedTabList = true,
  } = props;

  return (
    <TabsContext.Provider
      value={{
        activeTabId,
        changeTab: onTabChanged,
        prefix,
      }}
    >
      <section
        className={className}
        data-id={prefix}
      >
        <TabList
          sx={{
            ...tabListStyles,
            overflow: 'auto',
          }}
          indented={indentedTabList}
        >
          {tabs}
        </TabList>
        {tabs.map(({ props }) => (
          <TabPanel
            id={props.id}
            key={props.id}
            lazy={props.lazy}
            sx={{
              paddingTop: 's',
              ...tabPanelStyles,
            }}
          >
            {props.children}
          </TabPanel>
        ))}
      </section>
    </TabsContext.Provider>
  );
};
