import React from 'react';

import { ComponentProps } from '@Components';
import { ThemeDefinition } from '@Themes';
import { TitleVariants } from '@Tokens';

export interface TitleProps extends ComponentProps {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  /**
   * The Title variant.
   */
  variant: TitleVariants;
}

export const Title: React.FC<React.PropsWithChildren<TitleProps>> = ({
  as: Wrapper,
  variant,
  ...props
}) => (
  <Wrapper
    sx={(theme: ThemeDefinition) => theme.typography.title[variant]}
    {...props}
  />
);
