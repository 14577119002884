/**
  - `above` the view (offscreen)
  - `top` half of the view
  - `bottom` half of the view
  - `below` the view (offscreen)
*/
type Position = 'above' | 'top' | 'bottom' | 'below';

export function anchorPointsRelationToView(
  anchorPointElement: HTMLElement, view: { top: number; middle: number; bottom: number },
): Position {
  const bounds = anchorPointElement.getBoundingClientRect();
  if (bounds.top < view.top) {
    return 'above';
  } if (bounds.top < view.middle) {
    return 'top';
  } if (bounds.top < view.bottom) {
    return 'bottom';
  }

  return 'below';
}

function positionIsInView(position: Position | null): boolean {
  return position === 'top' || position === 'bottom';
}

/**
  Compute if an anchor point is significantly in view by looking at how much of its corresponding
  content is in view.

  An anchor is suitable to show as selected if its content is significantly in view.
  If the content is totally in view that works.
  - Totally in view would be if the anchor and the following anchor are on screen.
  - Or the anchor is above the screen and the following anchor is below the screen.
  - Or the anchor is above the screen and there isn't a following anchor.

  If the content is taking up more than 50% of the view that also works.
  - If the anchor is above the screen and the following is on the bottom half.
  - If the anchor is in the top half, and the following anchor is below the screen.
  - If the anchor and the following are both on screen (covered above).

  After clicking a link, the anchor point should always meet either of these conditions.
*/
export function anchorContentIsSignificantlyInView(
  position: Position, followingPosition: Position,
): boolean {
  if (positionIsInView(position) && positionIsInView(followingPosition)) {
    return true;
  }

  if (position === 'above' && (followingPosition === 'bottom' || followingPosition === 'below')) {
    return true;
  }

  if (position === 'top' && followingPosition === 'below') {
    return true;
  }

  if ((position === 'above' || position === 'top') && followingPosition === undefined) {
    return true;
  }

  return false;
}
