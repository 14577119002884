import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { ComponentProps, Label } from '@Components';
import {
  CounterVariant, CounterSize, counterVariantStyles,
} from '@Tokens';

export interface CounterProps extends Pick<ComponentProps, 'className' | 'data-id'> {
  /**
   * The color variant
   */
  variant: CounterVariant;
  size: CounterSize;
  currentCount: number;
  total: number;
}

const counterStyles: SxStyleProp = {
  display: 'inline-flex',
  paddingX: '3xs',
  borderRadius: 'rounded',
  alignItems: 'center',
};

export const Counter: React.FC<React.PropsWithChildren<CounterProps>> = ({
  'data-id': dataId,
  className,
  variant,
  currentCount,
  total,
  size,
}) => (
  <div
    className={className}
    data-id={dataId}
    sx={{
      height: size,
      ...counterStyles,
      ...counterVariantStyles[variant] as SxStyleProp,
    }}
  >
    <Label
      sx={{
        paddingX: '4xs',
      }}
      variant="extrasmall"
    >
      {currentCount}/{total}
    </Label>
  </div>
);
