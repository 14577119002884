import React, { forwardRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import {
  Icon, IconProps, Label,
  ClickableProps, Clickable,
} from '@Components';

type TextLinkVariants = 'Primary'
| 'Secondary'
| 'Critical'
| 'Light';

const variantStyles: Record<TextLinkVariants, SxStyleProp> = {
  Primary: {
    color: 'textPrimarydark',
  },
  Secondary: {
    color: 'textDefault',
  },
  Critical: {
    color: 'textCriticaldark',
  },
  Light: {
    color: 'textLight',
  },
};

export interface TextLinkProps extends ClickableProps {
  text: string;
  variant: TextLinkVariants;
  size: 24 | 20;
  icon?: IconProps['name'];
  iconPosition?: 'left' | 'right';
}

export const TextLink = forwardRef<HTMLDivElement, TextLinkProps>(({
  className,
  'data-id': dataId,
  text,
  variant,
  size,
  icon,
  disabled,
  iconPosition = 'left',
  ...restProps
}, ref) => {
  const iconSize: IconProps['size'] = size === 24 ? '20' : '16';

  return (
    <Clickable
      className={className}
      data-id={dataId}
      disabled={disabled}
      ref={ref}
      {...restProps}
      sx={disabled ? {} : {
        '&:hover > *, &:focus > *, &:hover, &:focus': {
          textDecoration: 'none',
        },
      }}
    >
      <Label
        variant={size === 24 ? 'large' : 'small'}
        sx={{
          fontWeight: 'bold',
          textDecoration: 'underline',
          ...(variantStyles[variant]),
          ...(disabled && {
            color: 'textDisabled',
          }),
        }}
      >
        {iconPosition === 'left' && icon && (
          <Icon
            size={iconSize}
            name={icon}
            sx={{
              marginRight: '3xs',
              verticalAlign: 'text-bottom',
            }}
          />
        )}
        {text}
        {iconPosition === 'right' && icon && (
          <Icon
            size={iconSize}
            name={icon}
            sx={{
              marginLeft: '3xs',
              verticalAlign: 'text-bottom',
            }}
          />
        )}
      </Label>
    </Clickable>
  );
});

