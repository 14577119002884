import React from 'react';
import { SxStyleProp } from 'theme-ui';

import {
  Icon, Label, ComponentProps, Clickable, Paragraph,
} from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';
import { ColorsPalette, ThemeDefinition } from '@Themes';
import { fadeIn, Icons, NewButtonType } from '@Tokens';

export type ToastType = 'Success' | 'Neutral' | 'NeutralInverted' | 'Warning' | 'Critical';

export interface ToastProps extends ComponentProps {
  type: ToastType;
  label: string;
  noIcon?: boolean;
  onClose?: () => void;
  onAction?: () => void;
  actionText?: string;
  noAnimation?: boolean;
}

type ToastOptions = {
  iconName: Icons;
  textColor: keyof ColorsPalette;
  iconColor: keyof ColorsPalette;
  textLinkVariant: 'Light' | 'Secondary';
  buttonVariant: NewButtonType;
  buttonStyles?: SxStyleProp;
  containerStyle: SxStyleProp;
};

const toastOptions: Record<ToastType, ToastOptions> = {
  Success: {
    iconName: 'Markers/ConfirmationFilled',
    textColor: 'textLight',
    iconColor: 'iconLight',
    textLinkVariant: 'Light',
    buttonVariant: 'Success',
    containerStyle: {
      backgroundColor: 'backgroundSuccessdark',
    },
  },
  Neutral: {
    iconName: 'Markers/ConfirmationFilled',
    textColor: 'textDefault',
    iconColor: 'iconDefault',
    textLinkVariant: 'Secondary',
    buttonVariant: 'TextSecondary',
    containerStyle: {
      backgroundColor: 'backgroundWhite',
    },
  },
  NeutralInverted: {
    iconName: 'Markers/ConfirmationFilled',
    textColor: 'textLight',
    iconColor: 'iconLight',
    textLinkVariant: 'Light',
    buttonVariant: 'TextSecondary',
    // @TODO: Replace below styles with an inverted variant once defined in DS
    buttonStyles: {
      color: 'textLight',
      backgroundColor: 'transparent',
    },
    containerStyle: {
      backgroundColor: 'backgroundDark',
    },
  },
  Warning: {
    iconName: 'Markers/AlertFilled',
    textColor: 'textLight',
    iconColor: 'iconLight',
    textLinkVariant: 'Light',
    buttonVariant: 'Warning',
    containerStyle: {
      backgroundColor: 'backgroundWarningdark',
    },
  },
  Critical: {
    iconName: 'Markers/AlertFilled',
    textColor: 'textLight',
    iconColor: 'iconLight',
    textLinkVariant: 'Light',
    buttonVariant: 'Critical',
    containerStyle: {
      backgroundColor: 'backgroundCriticaldark',
    },
  },
};

export const ToastContainerStyles: SxStyleProp = {
  borderRadius: '8',
  paddingX: 's',
  paddingY: 'xs',
  boxShadow: 'elevationHover',
  animation: ({ motion }: ThemeDefinition) => `${fadeIn} ${motion.fadeinoutCheetah.duration}ms ${motion.fadeinoutCheetah.easing}`,
};

export const Toast: React.FC<React.PropsWithChildren<ToastProps>> = ({
  type,
  noIcon,
  label,
  className,
  actionText,
  onAction = () => {},
  onClose = () => {},
  noAnimation,
  'data-id': dataId,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={className}
      data-id={dataId}
      sx={{
        ...ToastContainerStyles,
        flex: '1',
        paddingY: actionText ? 'l' : 'xs',
        width: [ null, null, '400px' ],
        display: 'flex',
        alignItems: 'center',
        gap: '3xs',
        ...(noAnimation && { animation: null }),
        ...toastOptions[type].containerStyle,
      }}
    >
      {!noIcon && (
        <Icon
          name={toastOptions[type].iconName}
          size="24"
          color={toastOptions[type].iconColor}
        />
      )}
      <Label
        variant="large"
        sx={{
          color: toastOptions[type].textColor,
          flexGrow: 1,
        }}
      >
        {label}
      </Label>
      {actionText ? (
        <Clickable
          onClick={onAction}
        >
          <Paragraph
            variant="smallunderline"
            sx={{
              color: toastOptions[type].iconColor,
            }}
          >
            {actionText}
          </Paragraph>
        </Clickable>
      ) : (
        <Clickable
          onClick={onClose}
          ariaLabel={t('close')}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
          }}
        >
          <Icon
            size="20"
            name="Actions/Cancel"
            color={toastOptions[type].iconColor}
          />
        </Clickable>
      )}
    </div>
  );
};
