import React, { Fragment } from 'react';

import { ComponentProps } from '@Components';
import { PopoverProps, usePopover } from '@Hooks';

export const Popover: React.FC<React.PropsWithChildren<PopoverProps & ComponentProps>> = ({
  'data-id': dataId,
  className,
  content,
  children,
  altText,
  trigger = 'click',
  alwaysOpened = false,
  placement,
}) => {
  const { triggerProps, portal } = usePopover({
    content, alwaysOpened, trigger, placement,
  });

  return (
    <Fragment>
      <button
        data-id={dataId}
        className={className}
        type="button"
        title={altText}
        {...triggerProps}
      >
        {children}
      </button>
      {portal}
    </Fragment>
  );
};
