import React, { forwardRef, ReactNode, Ref } from 'react';
import { SxStyleProp } from 'theme-ui';

import {
  Image, ImageType, ImageProps, ComponentProps, Skeleton,
} from '@Components';
import { mediaBrick } from '@Utils';

type CardVariant =
  | 'Responsive'
  | 'ResponsiveTallToWide'
  | 'Grid'
  | 'SmallWide'
  | 'SmallTall';

export interface ImageGallery {
  images: ImageType[];
  width: ImageProps['width'];
  height?: ImageProps['height'];
  lazyImage?: boolean;
  showFullScreenButton?: boolean;
}

export type CardProps = ComponentProps & {
  /**
   * Whether the card content has a transparent background
   */
  floating?: boolean;
  variant: CardVariant;
  topSashes?: ReactNode;
  topRightComponent?: ReactNode; // :shrug:
  bottomSash?: ReactNode;
  imageComponent?: ReactNode;
  ref?: Ref<any>;
  loading?: boolean;
  loadingContent?: ReactNode;
  fallbackImageSrc?: string;
  lazyImage?: boolean;
  galleryComponent?: (props: ImageGallery) => JSX.Element;
} & (
  {
    images: ImageType[];
    height: number | [number, number, number];
  } |
  {
    images?: undefined;
    height?: undefined;
  }
);

const containerStyles: Record<CardVariant, SxStyleProp> = {
  Responsive: {
    flexDirection: [ 'column', 'row' ],
  },
  ResponsiveTallToWide: {
    flexDirection: [ 'row', 'column' ],
  },
  Grid: {
    flexDirection: 'column',
  },
  SmallWide: {
    flexDirection: 'row',
  },
  SmallTall: {
    flexDirection: 'column',
  },
};

const imageStyles = (
  loading?: boolean,
  images?: boolean,
  floating?: boolean,
): Record<CardVariant, SxStyleProp> => ({
  Responsive: {
    ...(images ? { flex: [ null, '0 0 50%', '0 0 38%' ] } : {}),
    ...(floating ? { borderRadius: '12' } : {}),
    ...(floating && loading ? { borderRadius: '12' } : {}),
  },
  ResponsiveTallToWide: {
    ...(images ? { flex: [ '0 0 47%', null ] } : {}),
    ...(floating ? { borderRadius: '12' } : {}),
  },
  Grid: {
    ...(floating ? { borderRadius: '12' } : {}),
  },
  SmallWide: {
    ...(images ? { flex: [ '0 0 47%', null ] } : {}),
    ...(floating ? { borderRadius: '12' } : {}),
  },
  SmallTall: {
    ...(floating ? { borderRadius: '12' } : {}),
  },
});

const contentStyles = (
  floating?: boolean,
): Record<CardVariant, SxStyleProp> => ({
  Responsive: {
    padding: [ 's', 'l', 'xl' ],
    ...{ paddingLeft: floating ? 0 : undefined },

    ...mediaBrick({
      padding: '2xs',
      ...{ paddingLeft: floating ? 0 : undefined },
    }),
  },
  ResponsiveTallToWide: {
    padding: 'xs',
    paddingLeft: [ 'xs', floating ? 0 : 'xs' ],
  },
  Grid: {
    padding: 's',
    height: '100%',
    ...{ paddingLeft: floating ? 0 : undefined },

    ...mediaBrick({
      padding: '2xs',
      ...{ paddingLeft: floating ? 0 : undefined },
    }),
  },
  SmallWide: {
    padding: 'xs',
  },
  SmallTall: {
    padding: 'xs',
    ...{ paddingLeft: floating ? 0 : undefined },
  },
});

const getImageDimensions = (
  height: number | [number, number, number],
  variant: CardVariant,
): Pick<ImageProps, 'width' | 'height'> => {
  switch (variant) {
    case 'Grid':
      return { width: 225, height };
    case 'SmallWide':
      return { width: 144, height };
    case 'SmallTall':
      return { width: 140, height };
    case 'ResponsiveTallToWide':
      return {
        width: [ 140, 140, 144 ],
        height,
      };
    case 'Responsive':
    default: {
      const [ mobileHeight, tabletHeight, desktopHeight ] = typeof height === 'number' ? [ height, height, height ] : height;

      return {
        width: [
          Math.ceil(mobileHeight * 1.8),
          Math.ceil(tabletHeight * (4 / 3)),
          Math.ceil(desktopHeight * (4 / 3)),
        ],
        height,
      };
    }
  }
};

const defaultLoadingContent = (
  <div>
    <Skeleton sx={{ height: '2em', width: '60%', marginBottom: '1em' }} />
    <Skeleton sx={{ height: '1em', width: '50%', marginBottom: '3em' }} />

    <Skeleton sx={{ height: '2em', width: '50%', marginBottom: '1em' }} />
    <Skeleton sx={{ height: '2em', width: '100%' }} />
  </div>
);

export const Card = forwardRef<HTMLDivElement, React.PropsWithChildren<CardProps>>(
  (
    {
      'data-id': dataId,
      variant,
      images,
      children,
      floating = false,
      topSashes,
      topRightComponent,
      bottomSash,
      imageComponent,
      height,
      className,
      loading,
      loadingContent,
      fallbackImageSrc,
      lazyImage = true,
      galleryComponent: GalleryComponent,
    },
    ref,
  ) => {
    const shouldRenderTopSashes = topSashes && !loading;
    const shouldRenderBottomSash = bottomSash && !loading;

    return (
      <div
        data-id={dataId}
        ref={ref}
        className={className}
        sx={{
          borderRadius: '12',
          display: 'flex',
          overflow: 'hidden',
          ...(!floating && {
            boxShadow: 'elevationElevated',
            backgroundColor: 'white',
          }),
          ...containerStyles[variant],
        }}
      >
        <div
          sx={{
            position: 'relative',
            ...imageStyles(loading, !!images)[variant],
          }}
        >
          {loading && (
            <Skeleton
              sx={{
                ...imageStyles(loading, !!images, floating)[variant],
                height,
              }}
            />
          )}
          {!!images && height && images.length <= 1
            && !loading
            && (imageComponent || (
              <Image
                src={images[0]?.url || fallbackImageSrc}
                alt={images[0]?.description}
                {...getImageDimensions(height, variant)}
                lazy={lazyImage}
                sx={{
                  ...imageStyles(loading, !!images, floating)[variant],
                }}
              />
            ))}
          {!!images && height && images.length > 1 && !loading && GalleryComponent && (
            <GalleryComponent
              images={images}
              {...getImageDimensions(height, variant)}
              lazyImage={lazyImage}
              showFullScreenButton={!topRightComponent}
            />
          )}
          {!!topRightComponent && (
            <div
              sx={{
                position: 'absolute',
                right: '3xs',
                top: '3xs',
              }}
            >
              {topRightComponent}
            </div>
          )}
          {shouldRenderTopSashes && (
            <div
              sx={{
                position: 'absolute',
                left: '3xs',
                top: '3xs',
                '> *': {
                  marginRight: '3xs',
                },
              }}
            >
              {topSashes}
            </div>
          )}
          {shouldRenderBottomSash && (
            <div
              sx={{
                position: 'absolute',
                right: '3xs',
                bottom: '3xs',
              }}
            >
              {bottomSash}
            </div>
          )}
        </div>
        <div
          sx={{
            ...contentStyles(floating)[variant],
            width: '100%',
          }}
        >
          {loading ? loadingContent || defaultLoadingContent : children}
        </div>
      </div>
    );
  },
);
