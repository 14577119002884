import React from 'react';

import { ChoiceControl, ChoiceControlProps } from '../ChoiceControl/ChoiceControl';

export type RadioProps = Pick<ChoiceControlProps, 'checked' | 'disabled' | 'onChange' | 'data-id'>;

export const Radio: React.FC<React.PropsWithChildren<RadioProps>> = (props) => (
  <ChoiceControl
    type="radio"
    iconOn="Toggles/RadioOn"
    iconOff="Toggles/RadioOff"
    {...props}
  />
);
