import React from 'react';
import { SxStyleProp } from 'theme-ui';

import {
  ComponentProps,
  Label,
} from '@Components';
import { LabelVariants } from '@Tokens';

export const badgeValueCategories = [ 'Location', 'Luxury', 'Couples', 'Family', 'Wellness', 'Value', 'Subtle', 'Promotion', 'Discount', 'Deposit', 'BestOffer', 'Unavailable', 'Black Friday' ];

export type BadgeValueCategory = typeof badgeValueCategories[number];

// eslint-disable-next-line max-len
const badgeValueConfigs: Record<BadgeValueCategory, BadgeValueConfig> = {
  Location: {
    variantStyles: {
      backgroundColor: '#ffe68d',
      borderColor: '#ffe68d',
    },
  },
  Luxury: {
    variantStyles: {
      backgroundColor: '#d2aaf5',
      borderColor: '#d2aaf5',
    },
  },
  Couples: {
    variantStyles: {
      backgroundColor: '#FE9DD1',
      borderColor: '#FE9DD1',
    },
  },
  Family: {
    variantStyles: {
      backgroundColor: '#B4BBFF',
      borderColor: '#B4BBFF',
    },
  },
  Wellness: {
    variantStyles: {
      backgroundColor: '#88E3D3',
      borderColor: '#88E3D3',
    },
  },
  Value: {
    variantStyles: {
      backgroundColor: '#FFAB91',
      borderColor: '#FFAB91',
    },
  },
  Subtle: {
    variantStyles: {
      backgroundColor: '#F2F2F8',
      borderColor: '#F2F2F8',
    },
  },
  Promotion: {
    variantStyles: {
      backgroundColor: '#D43B3B',
      borderColor: '#D43B3B',
      color: 'textLight',
      fontWeight: 700,
    },
  },
  Discount: {
    variantStyles: {
      backgroundColor: 'backgroundAttention',
      borderColor: 'backgroundAttention',
      color: 'textLight',
    },
  },
  Deposit: {
    variantStyles: {
      backgroundColor: '#E4F1FF',
      borderColor: '#E4F1FF',
    },
  },
  BestOffer: {
    variantStyles: {
      backgroundColor: 'backgroundWhite',
      borderColor: 'backgroundWhite',
    },
    text: 'Best Offer',
  },
  Unavailable: {
    variantStyles: {
      backgroundColor: 'backgroundDark',
      borderColor: 'backgroundDark',
      color: 'textLight',
    },
  },
  'Black Friday': {
    variantStyles: {
      backgroundColor: 'backgroundDark',
      borderColor: 'backgroundDark',
      color: 'baseYellow',
      fontWeight: 700,
    },
  },
};

export type BadgeBookingStateColorVariant = 'Light' | 'Dark';

export interface BadgeValueProps extends ComponentProps {
  category: BadgeValueCategory;
}
export interface BadgeValueConfig {
  variantStyles: SxStyleProp;
  variant?: LabelVariants;
  text?: string;
}

export const BadgeValue: React.FC<React.PropsWithChildren<BadgeValueProps>> = ({
  className,
  'data-id': dataId,
  children,
  category,
}) => {
  const config = badgeValueConfigs[category];

  if (!config) {
    return null;
  }

  return (
    <Label
      className={className}
      data-id={dataId}
      sx={{
        whiteSpace: 'nowrap',
        paddingX: '3xs',
        borderRadius: 'rounded',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '4xs',
        borderWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        color: 'textDefault',
        minHeight: '28px',
        alignContent: 'center',
        ...(config.variantStyles),
      }}
      variant={config.variant ?? 'small'}
    >
      {children ?? config.text ?? category}
    </Label>
  );
};
