import React from 'react';

import {
  Icon, Label, Maybe, ComponentProps,
} from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';

export interface HolidayCheckRatingProps
  extends Pick<ComponentProps, 'className' | 'data-id'> {
  /**
   * Number of reviews
   */
  reviewCount?: Maybe<number>;
  /**
   * Star rating
   */
  rating: Maybe<number>;
  /**
   * Recommendation that convert to a %
   */
  recommendation?: Maybe<number>;
  /**
   * Size
   */
  size?: 'default' | 'large';
  /**
   * Display
   */
  display?: 'default' | 'recommendation' | 'rating';
}

const TOTAL_RATING = 6;

export const HolidayCheckRating: React.FC<React.PropsWithChildren<HolidayCheckRatingProps>> = ({
  rating,
  reviewCount,
  recommendation,
  'data-id': dataId = 'rating',
  className,
  size = 'default',
  display = 'default',
}) => {
  const { t } = useTranslation();

  if (!rating) {
    return null;
  }

  return (
    (
      <div
        className={className}
        data-id={dataId}
        aria-label={t('brandReviewRating', { brand: 'holidaycheck', rating })}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        { display !== 'rating' && (
          <span
            sx={{
              backgroundColor: '#0E55CD',
              display: 'flex',
              color: 'white',
              ...(display === 'recommendation' ? {
                borderRadius: '4',
              } : {
                borderTopLeftRadius: '4',
                borderBottomLeftRadius: '4',
              }),
              alignItems: 'center',
              height: size === 'default' ? 28 : 32,
              paddingLeft: '3xs',
              paddingRight: '3xs',
              whiteSpace: 'nowrap',
              marginRight: reviewCount && display === 'recommendation' ? '2xs' : 0,
            }}
          >
            <Icon
              name="Markers/HolidayCheckRecommendation"
              size="20"
              sx={{
                color: 'white',
                marginRight: '4xs',
              }}
            />
            <Label variant={size === 'default' ? 'extrasmallbold' : 'smallbold'}>
              { recommendation }%
            </Label>
          </span>
        )}
        { display !== 'recommendation' && (
          <span
            sx={{
              backgroundColor: 'white',
              border: '1px solid #D9E1EE',
              display: 'flex',
              color: '#000820',
              ...(display === 'rating' ? {
                borderRadius: '4',
              } : {
                borderTopRightRadius: '4',
                borderBottomRightRadius: '4',
              }),
              alignItems: 'center',
              height: size === 'default' ? 28 : 32,
              paddingLeft: '3xs',
              paddingRight: '3xs',
              whiteSpace: 'nowrap',
              marginRight: reviewCount ? '2xs' : 0,
            }}
          >
            <Icon
              name="Markers/HolidayCheckSun"
              size="20"
              sx={{
                color: '#FFCA3A',
                marginRight: '4xs',
              }}
            />
            <Label variant={size === 'default' ? 'extrasmallbold' : 'smallbold'}>
              {t('holidayCheckRating', { rating })}
            </Label>
            <Label variant={size === 'default' ? 'extrasmall' : 'small'}>
              &nbsp;/ {TOTAL_RATING}
            </Label>
          </span>
        )}
        {!!reviewCount && (
          <Label
            variant="extrasmall"
            sx={{
              color: 'textDimmedmedium',
              '&:hover': {
                color: 'textDimmedmedium',
                textDecoration: 'underline',
              },
            }}
            aria-label={t('basedOnXReviews', { count: reviewCount })}
          >
            {t('xReviews', { count: reviewCount })}
          </Label>
        )}
      </div>
    )
  );
};
