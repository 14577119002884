import React, { KeyboardEvent, useRef } from 'react';

import { useTabsContext } from './TabsContext';
import { ComponentProps } from '@Components';
import { Key } from '@Key';

export interface TabListProps extends ComponentProps {
  /** Show tabs with an indentation (default). Use false to line up with other components. */
  indented?: boolean;
}

export const TabList = (
  { indented, children, className }: React.PropsWithChildren<TabListProps>,
) => {
  const { activeTabId, changeTab } = useTabsContext();

  const ref = useRef<HTMLDivElement>(null);

  const getNextTab = (direction: Key.ArrowRight | Key.ArrowLeft) => {
    const allTabs: HTMLElement[] = Array.from(ref.current?.querySelectorAll('[role=tab]')!);
    const currentTabIndex = allTabs.findIndex((el) => el.dataset.id === activeTabId);

    let nextTabIndex = 0;

    if (direction === Key.ArrowRight) {
      nextTabIndex = currentTabIndex === allTabs.length - 1
        ? 0
        : currentTabIndex + 1;
    }

    if (direction === Key.ArrowLeft) {
      nextTabIndex = currentTabIndex === 0
        ? allTabs.length - 1
        : currentTabIndex - 1;
    }

    return allTabs[nextTabIndex];
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (![ Key.ArrowRight, Key.ArrowLeft ].includes(e.key as Key)) {
      return;
    }

    const nextTab = getNextTab(e.key as Key.ArrowRight | Key.ArrowLeft);
    nextTab.focus();
    changeTab(nextTab.dataset.id!);
  };

  return (
    (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus
      <div
        className={className}
        ref={ref}
        role="tablist"
        onKeyDown={handleKeyDown}
        sx={{
          display: 'flex',
          gap: 'xl',
          paddingX: indented ? 'xl' : null,
          borderBottomStyle: 'solid',
          borderBottomWidth: 'outlinedStrokeWeight',
          borderColor: 'strokeLightsubtle',
        }}
      >
        {children}
      </div>
    )
  );
};
