import { useState } from 'react';

// @TODO: this is not the best solution as IDs will be re-generated during hydration.
// https://github.com/preactjs/preact/issues/3373
const generateId = () => `ds-${Math.random().toString(36).substr(2, 9)}`;

/** Generate a static unique id. Stand in for when it is officially supported. */
export const useId = () => {
  const [ id ] = useState(generateId);

  return id;
};
