import React from 'react';

import { generatePanelId } from './generatePanelId';
import { generateTabId } from './generateTabId';
import { useTabsContext } from './TabsContext';
import { ComponentProps } from '@Components';

export interface TabPanelProps extends ComponentProps {
  id: string;
  lazy?: boolean;
}

export const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = ({
  id,
  lazy,
  children,
  className,
}) => {
  const { activeTabId, prefix } = useTabsContext();

  const isActive = activeTabId === id;

  return (
    <div
      role="tabpanel"
      aria-labelledby={generateTabId(prefix, id)}
      id={generatePanelId(prefix, id)}
      hidden={!isActive}
      className={className}
    >
      {isActive || !lazy ? children : null}
    </div>
  );
};
