import React, { forwardRef, TextareaHTMLAttributes } from 'react';

import {
  InputFieldDecoration, InputFieldDecorationProps, inputFieldStyles,
} from './InputFieldDecoration';
import { useId } from '@Hooks';

export interface InputFieldTextAreaProps
  extends
  Pick<InputFieldDecorationProps, 'label' | 'subtitle' | 'message' | 'size' | 'value' | 'state' | 'data-id'>,
  Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, 'wrap' | 'disabled' | 'placeholder' | 'onChange' | 'maxLength'> {}

export const InputFieldTextArea = forwardRef<HTMLTextAreaElement, InputFieldTextAreaProps>(({
  label,
  subtitle,
  message,
  state,
  size,
  value,
  'data-id': dataId,
  ...rest
}, ref) => {
  const id = useId();

  return (
    <InputFieldDecoration
      id={id}
      label={label}
      subtitle={subtitle}
      message={message}
      size={size}
      value={value}
      state={state}
      data-id={dataId}
    >
      <textarea
        ref={ref}
        value={value}
        disabled={state === 'Disabled'}
        id={id}
        sx={{
          ...inputFieldStyles({ state }),
          paddingY: '2xs',
          width: '100%',
          resize: 'none',
        }}
        {...rest}
      />
    </InputFieldDecoration>
  );
});
