import React, { useState, useEffect } from 'react';
import { SxStyleProp } from 'theme-ui';

import { ComponentProps, keyboardOutline } from '@Components';
import { negativeSpace } from '@Utils';

const trackStyles = (progress: number): SxStyleProp => ({
  appearance: 'none',
  background: ({ colors }) => `linear-gradient(
    90deg,
    ${colors.interactiveSelected} ${progress}%,
    ${colors.interactiveDisabledlight} ${progress}% 100%
  )`,
  borderColor: 'transparent',
  color: 'transparent',
  height: ({ space }) => space['4xs'],
});

const thumbStyles: SxStyleProp = {
  appearance: 'none',
  width: 20,
  height: 20,
  marginTop: negativeSpace('3xs'),
  backgroundColor: 'backgroundWhite',
  boxShadow: ({ colors }) => `0 0 0 6px ${colors.interactiveSelected} inset`,
  borderWidth: 0,
  borderRadius: 'rounded',
};

export interface RangeSliderProps extends ComponentProps {
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  showProgressLabel?: boolean;
  onChange?: (value: number) => void;
  formatProgressLabel?: (immediate: number, min: number) => string;
  formatValue?: (value: number) => string;
  ariaLabel?: string;
}

export const RangeSlider: React.FC<React.PropsWithChildren<RangeSliderProps>> = ({
  min = 0,
  max = 100,
  value = 1,
  step = 1,
  showProgressLabel = false,
  onChange = () => {},
  formatProgressLabel,
  formatValue = String,
  className,
  ariaLabel,
}) => {
  /**
   * The `onChange` handler is triggered continuously when dragging the slider without releasing
   * the mouse button. We can use this event to read immediate values from the slider to update
   * the background and the progress label, but we should not use this to call the passed `onChange`
   * prop, as that would be called too frequently. Instead, we just store the value in `onChange`
   * handler to an immediate value.
   * `onMouseUp` and `onKeyUp` handlers are triggered when the user actually stopped interacting
   * with the slider, so we can use this event to propagate the new value to the parent element.
   */
  const [ immediateValue, setImmediateValue ] = useState(value);
  const valueAsPercentage = ((immediateValue - min) / (max - min)) * 100;
  const onImmediateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImmediateValue(Number(e.target.value));
  };
  const onFinalChange = (e: any) => {
    const newValue = Number(e.target.value);

    if (value !== newValue) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (value !== immediateValue) {
      setImmediateValue(value);
    }
  }, [ value ]);

  return (
    <section
      className={className}
    >
      {showProgressLabel && (
        <p>
          {formatProgressLabel
            ? formatProgressLabel(immediateValue, min)
            : `${formatValue(min)} - ${formatValue(immediateValue)}`}
        </p>
      )}

      <input
        aria-label={ariaLabel}
        type="range"
        min={min}
        aria-valuemin={min}
        max={max}
        aria-valuemax={max}
        value={immediateValue}
        aria-valuenow={immediateValue}
        aria-valuetext={formatValue(immediateValue)}
        step={step}
        onChange={onImmediateChange}
        onKeyUp={onFinalChange}
        onMouseUp={onFinalChange}
        onTouchEnd={onFinalChange}
        sx={{
          appearance: 'none',
          height: '28px',
          cursor: 'pointer',

          // Chrome, Safari
          '::-webkit-slider-runnable-track': trackStyles(valueAsPercentage),
          '::-webkit-slider-thumb': thumbStyles,

          // Firefox
          '::-moz-range-track': trackStyles(valueAsPercentage),
          '::-moz-range-thumb': thumbStyles,

          // IE 11
          '::-ms-track': trackStyles(valueAsPercentage),
          '::-ms-fill-lower': {
            backgroundColor: 'interactiveSelected',
          },
          '::-ms-fill-upper': {
            backgroundColor: 'interactiveDisabledlight',
          },
          '::-ms-thumb': {
            ...thumbStyles,
            marginTop: 0,
          },
          '::-ms-tooltip': {
            display: 'none',
          },
          ...keyboardOutline,
        }}
      />
    </section>
  );
};
