import React, { Fragment } from 'react';

import {
  Icon, Label, Maybe, ComponentProps,
} from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';
import { ColorsPalette } from '@Themes';
import { Icons } from '@Tokens';

export interface RatingProps
  extends Pick<ComponentProps, 'className' | 'data-id'> {
  /**
   * Number of reviews
   */
  reviewCount?: Maybe<number>;
  /**
   * Star rating
   */
  rating: Maybe<number>;
  /**
   * Size
   */
  size?: 'default' | 'large';
  /**
   * if true the content has a transparent background
   */
  floating?: boolean;
  /**
   * Variant to display the the Ration
   */
  variant?: 'inline' | 'stacked';
  /**
   * The Icon of the brand
   */
  brandIcon: Icons;
  /**
   * The Icon to be used for full points (i.e the first 3 if rating is 3.5)
   */
  filledIcon: Icons;
  /**
   * The Icon to be used for half points (i.e, the 4th if rating is 3.5)
   */
  halfIcon: Icons;
  /**
   * The Icon to be used for empty points (i.e, the 5th if rating is 3.5)
   */
  emptyIcon: Icons;
  /**
   * The brand to be used for the screen reader description
   */
  brand: string;
  /**
   * The color for the rating icons
   */
  ratingColor: keyof ColorsPalette;
}

const TOTAL_RATING = 5;

const Wrapper: React.FC<React.PropsWithChildren<{ variant: RatingProps['variant'] }>> = (
  { variant, children },
) => (variant === 'inline'
  ? <Fragment>{children}</Fragment>
  : (
    <span sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {children}
    </span>
  ));

export const Rating: React.FC<React.PropsWithChildren<RatingProps>> = ({
  rating,
  reviewCount,
  'data-id': dataId = 'rating',
  className,
  size = 'default',
  floating = true,
  brandIcon,
  filledIcon,
  halfIcon,
  emptyIcon,
  brand,
  ratingColor,
  variant,
}) => {
  const { t } = useTranslation();

  if (!rating) {
    return null;
  }

  return (
    <span
      className={className}
      data-id={dataId}
      aria-label={t('brandReviewRating', { brand, rating })}
      sx={{
        height: 24,
        boxSizing: 'content-box',
        padding: floating ? undefined : '3xs',
        borderRadius: 'rounded',
        display: 'inline-block',
        verticalAlign: 'middle',
        ...(!floating && {
          backgroundColor: 'backgroundWhite',
        }),
        '> svg': {
          verticalAlign: 'middle',
        },
        '&:hover': {
          color: 'textDimmedmedium',
          textDecoration: 'underline',
        },
      }}
    >
      <Wrapper variant={variant}>
        <Icon
          name={brandIcon}
          size="20"
          sx={{
            color: 'iconDefault',
            marginRight: '4xs',
          }}
        />
        {[ ...Array(TOTAL_RATING) ].map((_, idx) => {
          let iconName = filledIcon;
          if (idx < rating && idx + 1 > rating) {
            iconName = halfIcon;
          }
          if (idx >= rating) {
            iconName = emptyIcon;
          }

          return (
            <Icon
              key={idx}
              size={size === 'large' ? '16' : '12'}
              name={iconName}
              color={ratingColor}
            />
          );
        })}
      </Wrapper>
      {!!reviewCount && (
        <Wrapper variant={variant}>
          <Label
            variant="extrasmall"
            sx={{
              color: 'textDimmedmedium',
              marginLeft: '4xs',
            }}
            aria-label={t('basedOnXReviews', { count: reviewCount })}
          >
            {t('xReviews', { count: reviewCount })}
          </Label>
        </Wrapper>
      )}
    </span>
  );
};
