import React from 'react';

import { ComponentProps } from '@Components';
import { ThemeDefinition } from '@Themes';
import { ParagraphVariants } from '@Tokens';

export interface ParagraphProps extends Omit<ComponentProps, 'as'> {
  /**
   * The Paragraph variant.
   */
  variant: ParagraphVariants;
}

export const Paragraph: React.FC<React.PropsWithChildren<ParagraphProps>> = ({
  variant,
  ...props
}) => (
  <p
    sx={(theme: ThemeDefinition) => theme.typography.paragraph[variant]}
    {...props}
  />
);
