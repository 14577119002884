import React, { forwardRef, Fragment } from 'react';

import {
  ComponentProps, InputField, InputFieldProps,
} from '@Components';
import { useTooltip } from '@Hooks';
import { IncrementSizes } from '@Tokens';

type Variant = Pick<IncrementSizes, '64' | '48'>;

type HTMLProps = React.InputHTMLAttributes<HTMLInputElement> & ComponentProps;

export interface StandardInputProps extends Omit<HTMLProps, 'size'> {
  label?: string;
  /**
   * the Size variant
   */
  variant: keyof Variant;
  /**
   * Short and concise error message below the input
   */
  errorMessage?: string;
  /**
   * Help message to be shown in the tooltip triggered by the help-icon
   */
  helpMessage?: string;
}

const asInputFieldState: (props: Pick<StandardInputProps, 'disabled' | 'errorMessage'>) => InputFieldProps['state'] = ({
  disabled,
  errorMessage,
}) => {
  if (disabled) {
    return 'Disabled';
  }
  if (errorMessage) {
    return 'Error';
  }

  return 'Standard';
};

/**
 * @deprecated Use InputField instead
 */
export const StandardInput = forwardRef<HTMLInputElement, StandardInputProps>(({
  label,
  variant,
  errorMessage,
  helpMessage,
  disabled = false,
  id,
  ...restProps
}, ref) => {
  // ❗ Make sure this is the only hook used as it's conditional!
  const {
    portal = null, triggerProps = null,
  } = helpMessage ? useTooltip({ content: helpMessage }) : {};

  return (
    <Fragment>
      <InputField
        ref={ref}
        label={label}
        size={variant}
        state={asInputFieldState({ errorMessage, disabled })}
        message={disabled ? null : errorMessage}
        button={triggerProps ? {
          icon: 'Markers/Tooltip',
          ...triggerProps,
        } : null}
        {...restProps}
      />
      {portal}
    </Fragment>
  );
});
