import React, { isValidElement, useMemo } from 'react';

export type ModalComponentOrElement =
  React.FC<React.PropsWithChildren<{ onClose: () => void }>> |
  React.ReactElement<{ onClose: () => void }>;

export const createModalElement = (
  ComponentOrElement: ModalComponentOrElement,
  onClose: () => void,
) => useMemo(() => {
  if (!ComponentOrElement) {
    return null;
  }

  if (isValidElement(ComponentOrElement)) {
    return ComponentOrElement;
  }

  return <ComponentOrElement onClose={onClose} />;
}, [ ComponentOrElement, onClose ]);

const getModalSectionTopMobileStyle = (
  {
    mobileFloating,
    shouldCenterVerticallyMobile,
  }: {
    mobileFloating: boolean;
    shouldCenterVerticallyMobile: boolean;
  },
) => {
  if (shouldCenterVerticallyMobile) {
    return '50%';
  }

  if (mobileFloating) {
    return null;
  }

  return 0;
};

export const getModalSectionPositionStyles = (
  {
    mobileFloating,
    shouldCenterVerticallyMobile,
  }: {
    mobileFloating: boolean;
    shouldCenterVerticallyMobile: boolean;
  },
) => ({
  top: [ getModalSectionTopMobileStyle({ mobileFloating, shouldCenterVerticallyMobile }), '50%' ],
  right: 0,
  bottom: [ shouldCenterVerticallyMobile ? null : 0, 'auto' ],
  left: [ 0, '50%' ],
});
