import { useContext, createContext } from 'react';

interface TabsContextValue {
  activeTabId: string;
  prefix: string;
  changeTab: (tabId: string) => void;
}

export const TabsContext = createContext<TabsContextValue>({
  activeTabId: '',
  prefix: '',
  changeTab: () => {},
});

export const useTabsContext = () => useContext(TabsContext);
