import React from 'react';

import { Icon, ComponentProps, Maybe } from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';
import { IncrementSizes } from '@Tokens';

export type StarSizeVariants = 'default' | 'large' | 'extralarge';

export interface StarRatingProps
  extends Pick<ComponentProps, 'className' | 'data-id'> {
  rating: Maybe<number>;
  size?: StarSizeVariants;
}

const sizeMapping: Partial<Record<StarSizeVariants, keyof IncrementSizes>> = {
  default: '12' as keyof IncrementSizes,
  large: '16' as keyof IncrementSizes,
  extralarge: '24' as keyof IncrementSizes,
};

export const StarRating: React.FC<StarRatingProps> = ({
  rating,
  'data-id': dataId,
  className,
  size = 'default',
}) => {
  const { t } = useTranslation();

  if (!rating) {
    return null;
  }

  return (
    <span
      className={className}
      data-id={dataId}
      role="img"
      aria-label={t('starRating', { rating })}
      sx={{
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: ({ sizes }) => `${sizes['12']}px`,
        '& > *:not(:last-of-type)': {
          marginRight: '5xs',
        },
      }}
    >
      {[ ...Array(rating) ].map((_, idx) => (
        <Icon
          key={idx}
          size={sizeMapping[size] ?? sizeMapping.default}
          name="Markers/StarFilled"
        />
      ))}
    </span>
  );
};
