import React from 'react';

import { ComponentProps } from '@Components';
import { ThemeDefinition } from '@Themes';
import { HeadingVariants } from '@Tokens';
import { Responsive } from '@Utils';

export interface HeadingProps extends ComponentProps {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  /**
   * The Heading variant.
   */
  variant: Responsive<HeadingVariants>;
}

export const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  as: Wrapper,
  variant,
  ...props
}) => (
  <Wrapper
    sx={
      (theme: ThemeDefinition) => (Array.isArray(variant)
        ? { '&': variant.map((v) => theme.typography.heading[v]) }
        : theme.typography.heading[variant])
    }
    {...props}
  />
);
