import React, { useRef } from 'react';

/**
 * Hook to help animate the tranisition effects of `details` elements.
 *
 * It makes a few assumptions of how the `details` element is setup:
 * * The `summary` element has an icon that should spin 180° to indicate open and closed states
 * * The content should animate by changing its height. This gives a reveal effect.
 * * The content should have 2 `div`s wrapping it. The inner `div` is for measuring the height. The
 *   outer `div` is for setting the height.
 * * height and rotation are set correctly initially.
 */
export const useDetailsAnimation = ({
  collapsedByDefault, outerContentRef, innerContentRef, detailsRef, iconRef,
}: {
  collapsedByDefault: boolean;
  outerContentRef: React.RefObject<HTMLDivElement>;
  innerContentRef: React.RefObject<HTMLDivElement>;
  detailsRef: React.RefObject<HTMLDetailsElement>;
  iconRef: React.RefObject<SVGSVGElement>;
}): { onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void } => {
  const contentAnimationRef = useRef<NodeJS.Timeout | null>();
  const directionRef = useRef<'opening' | 'closing'>(collapsedByDefault ? 'closing' : 'opening');

  return {
    onClick: (event) => {
      const outerContentEl = outerContentRef.current;
      const innerContentEl = innerContentRef.current;
      const detailsEl = detailsRef.current;
      const iconEl = iconRef.current;
      if (outerContentEl === null
        || innerContentEl === null
        || detailsEl === null
        || iconEl === null) {
        return;
      }

      detailsEl.open = true;
      outerContentEl.style.height = `${innerContentEl.offsetHeight}px`;

      const computedStyle = window.getComputedStyle(outerContentEl);
      const duration = Number(computedStyle.transitionDuration.replace('s', '')) * 1000;
      directionRef.current = directionRef.current === 'closing' ? 'opening' : 'closing';

      if (contentAnimationRef.current) {
        clearTimeout(contentAnimationRef.current);
      }
      if (directionRef.current === 'closing') {
        iconEl.style.transform = 'rotate(0deg)';
        outerContentEl.style.height = '0';
        contentAnimationRef.current = setTimeout(() => {
          detailsEl.open = false;
        }, duration);
      } else {
        iconEl.style.transform = 'rotate(180deg)';
        contentAnimationRef.current = setTimeout(() => {
          outerContentEl.style.height = 'auto';
        }, duration);
      }

      event.preventDefault();
    },
  };
};
