import React, { forwardRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import {
  Clickable, ClickableProps, Label, Icon, IconProps, NotificationIcon,
} from '@Components';
import { ThemeDefinition } from '@Themes';
import { Icons } from '@Tokens';

export interface ActionButtonProps extends ClickableProps {
  type: 'Button' | 'Icon' | 'Navigation';
  icon: Icons;
  children?: string;
  notificationCount?: number;
}

const iconSize: IconProps['size'] = '24';

const getIconPadding = (theme: ThemeDefinition) => theme.space['3xs'];
export const getIconVariationWidth = (
  theme: ThemeDefinition,
) => getIconPadding(theme) * 2 + parseInt(iconSize, 10);
export const getIconVariationHeight = (
  theme: ThemeDefinition,
) => getIconPadding(theme) * 2 + parseInt(iconSize, 10);

const navigationStyles = {
  paddingRight: [ '3xs', null, 'xs' ],
  borderRadius: [ 'rounded', null, '8' ],
} as SxStyleProp;

const buttonStyles = {
  paddingRight: 'xs',
  borderRadius: '8',
} as SxStyleProp;

export const ActionButton = forwardRef<any, ActionButtonProps>(({
  children,
  type,
  icon,
  notificationCount,
  disabled = false,
  ...clickableProps
}, ref) => (
  <Clickable
    ref={ref}
    disabled={disabled}
    sx={(theme) => ({
      paddingLeft: getIconPadding(theme),
      paddingY: getIconPadding(theme),
      display: 'inline-flex',
      alignItems: 'center',
      backgroundColor: 'backgroundWhite',
      color: disabled ? 'textDisabled' : 'textDefault',
      '&:hover, &:active, &:visited': {
        color: disabled ? 'textDisabled' : 'textDefault',
      },
      '&:hover': {
        backgroundColor: disabled ? 'backgroundWhite' : 'backgroundLightsubtle',
      },
      ...(type === 'Navigation' && { ...navigationStyles }),
      ...(type === 'Button' && { ...buttonStyles }),
      ...(type === 'Icon' && {
        paddingRight: getIconPadding(theme),
        borderRadius: 'rounded',
      }),
    })}
    {...clickableProps}
  >
    {notificationCount !== undefined ? (
      <NotificationIcon
        count={notificationCount}
        name={icon}
        size={iconSize}
      />
    ) : (
      <Icon
        name={icon}
        size={iconSize}
      />
    )}

    {(type === 'Button' || type === 'Navigation') && (
      <Label
        sx={{
          marginLeft: '2xs',
          ...(type === 'Navigation' && {
            display: [ 'none', null, 'block' ],
          }),
        }}
        variant="smallbuttonunderline"
      >
        {children}
      </Label>
    )}
  </Clickable>
));
