import { ComponentProps } from 'components/types';
import React from 'react';

import { useInView } from '@Hooks';

export interface MarkerDivProps extends ComponentProps {
  onScreen: (isInView: boolean) => void;
}

/**
  Sentinel div to tell us when the following element is off screen.

  Can be used before a `position: sticky` element to know when they have entered the `sticky` state.
  https://developers.google.com/web/updates/2017/09/sticky-headers
 */
export const MarkerDiv: React.FC<React.PropsWithChildren<MarkerDivProps>> = ({ onScreen, id }) => {
  const sentinelRef = useInView((isInView) => {
    onScreen(isInView);
  }, { threshold: 1 });

  return (
    <div
      id={id}
      ref={sentinelRef}
    />
  );
};
