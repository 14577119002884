import React, { forwardRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import {
  Link,
  TriggerButton,
  TriggerEvent,
  keyboardOutline,
} from '@Components';
import type { LinkProps, TriggerButtonProps } from '@Components';

interface HrefBehaviourProps {
  href: string;
  asAnchor?: boolean;
  internal?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  rel?: string;
  trackingAction?: string;
  eventLabel?: string;
  'data-id'?: string;
}

export interface TriggerButtonBehaviourProps {
  // By using TriggerEvent we work with Links (a) and buttons
  onClick?: (e: TriggerEvent) => void;
  trackingAction?: string;
  eventLabel?: string;
  'data-id'?: string;
  onFocus?: (e: TriggerEvent) => void;
  onBlur?: (e: TriggerEvent) => void;
  onMouseEnter?: (e: TriggerEvent) => void;
  onMouseLeave?: (e: TriggerEvent) => void;
  onTouchEnd?: (e: TriggerEvent) => void;
}

export type ClickableBehaviourProps = HrefBehaviourProps | TriggerButtonBehaviourProps;
export interface ClickableProps extends Omit<LinkProps, 'href' | 'onBlur' | 'onClick' | 'onFocus' | 'onMouseEnter' | 'onMouseLeave' | 'onTouchEnd'>, TriggerButtonBehaviourProps {
  ariaLabel?: string;
  disabled?: boolean;
  buttonStyles?: SxStyleProp;
  linkStyles?: SxStyleProp;
  href?: string;
  as?: TriggerButtonProps['as'];
  buttonType?: TriggerButtonProps['buttonType'];
}

/**
 * A clickable component that can be used as a button or link.
 * It's used to provide a consistent API for both buttons and links.
 * It's also used to provide a consistent API for both internal and external links.
 *
 * Pass `href` to use as link, otherwise will render a button. Note that `href` can be used in
 * conjunction with `onClick`
 */
export const Clickable = forwardRef<any, ClickableProps>(({
  as,
  children,
  onClick = () => {},
  className,
  trackingAction,
  eventLabel,
  href,
  internal,
  'data-id': dataId,
  ariaLabel,
  disabled = false,
  target,
  rel,
  asAnchor,
  linkStyles,
  buttonStyles,
  onFocus,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  onTouchEnd,
  buttonType,
  'aria-describedby': ariaDescribedBy,
}, ref) => {
  const commonProps = {
    'aria-label': ariaLabel,
    className,
    'data-id': dataId,
    eventLabel,
    trackingAction,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    onTouchEnd,
    'aria-describedby': ariaDescribedBy,
    ref,
    children,
  };

  return (href ? (
    <Link
      asAnchor={asAnchor}
      internal={internal}
      target={target}
      rel={rel}
      href={href}
      onClick={onClick}
      sx={{
        ...linkStyles,
        ...keyboardOutline,
      }}
      aria-disabled={disabled}
      {...commonProps}
    />
  ) : (
    <TriggerButton
      as={as}
      disabled={disabled}
      onTrigger={onClick}
      sx={buttonStyles || {}}
      buttonType={buttonType}
      {...commonProps}
    />
  ));
});
