import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { Label, Clickable, ClickableBehaviourProps } from '@Components';

export interface PopoverListOptionProps {
  state?: 'Default' | 'Hover' | 'Selected' | 'Unavailable';
  clickable?: ClickableBehaviourProps;
}

const stateStyles: Record<PopoverListOptionProps['state'], SxStyleProp> = {
  Default: {
    backgroundColor: 'backgroundWhite',
    cursor: 'pointer',
  },
  Hover: {
    backgroundColor: 'backgroundPrimarylight',
    cursor: 'pointer',
  },
  Selected: {
    backgroundColor: 'backgroundBlack',
    color: 'textLight',
    cursor: 'pointer',
  },
  Unavailable: {
    backgroundColor: 'backgroundWhite',
    color: 'textDisabled',
  },
};

export const PopoverListOption: React.FC<React.PropsWithChildren<PopoverListOptionProps>> = ({ state = 'Default', clickable, children }) => {
  const outerStyles: SxStyleProp = {
    ...stateStyles[state],
    minWidth: 182,
    paddingY: '3xs',
    paddingX: 's',
    borderRadius: '4',
    ...(state === 'Default' && {
      '&:hover, &:focus-within': stateStyles.Hover,
    }),
  };

  if (state === 'Unavailable' || !clickable) {
    return (
      <Label
        sx={outerStyles}
        variant="medium"
      >
        {children}
      </Label>
    );
  }

  return (
    <Clickable
      {...clickable}
      sx={outerStyles}
    >
      <Label
        variant="medium"
      >
        {children}
      </Label>
    </Clickable>
  );
};
