/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import { useDetailsAnimation } from './useDetailsAnimation';
import {
  ComponentProps,
  DOMInteractionEvent,
  Icon,
  useTrackingContext,
  keyboardOutline,
} from '@Components';

export interface AccordionProps extends ComponentProps {
  accordionHeader: React.ReactElement;
  accordionHeaderStyle?: SxStyleProp;
  collapsedByDefault?: boolean;
  contentStyles?: SxStyleProp;
}

export const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  accordionHeader,
  accordionHeaderStyle,
  className,
  'data-id': dataId,
  children,
  collapsedByDefault = false,
  contentStyles,
  trackingAction,
  eventLabel,
}) => {
  const outerContentRef = useRef<HTMLDivElement>(null);
  const innerContentRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<SVGSVGElement>(null);
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const { onClick } = useDetailsAnimation({
    collapsedByDefault, outerContentRef, innerContentRef, detailsRef, iconRef,
  });
  const { trackEvent } = useTrackingContext();

  return (
    <details
      ref={detailsRef}
      open={!collapsedByDefault}
      className={className}
      data-id={dataId}
    >
      <summary
        sx={{
          cursor: 'pointer',
          listStyle: 'none',
          userSelect: 'none',
          '&::-webkit-details-marker': {
            display: 'none',
          },
          ...keyboardOutline,
        }}
        onClick={(e) => {
          if (trackingAction) {
            trackEvent(
              DOMInteractionEvent.CLICK,
              trackingAction,
              eventLabel
              || typeof accordionHeader.props.children === 'string'
                ? accordionHeader.props.children
                : undefined,
            );
          }

          onClick(e);
        }}
      >
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ...accordionHeaderStyle,
          }}
        >
          {accordionHeader}

          <Icon
            data-id="expand-on"
            name="Toggles/ExpandOn"
            size="20"
            ref={iconRef}
            sx={{
              display: 'inline',
              transitionProperty: 'transform',
              transform: collapsedByDefault ? null : 'rotate(180deg)',
              transitionDuration: (t) => `${t.motion.moveKangaroo.duration}ms`,
              transitionTimingFunction: (t) => t.motion.moveKangaroo.easing,
            }}
          />
        </div>
      </summary>

      <div
        ref={outerContentRef}
        sx={{
          overflow: 'hidden',
          transitionProperty: 'height',
          height: collapsedByDefault ? 0 : null,
          transitionDuration: ({ motion }) => `${motion.moveKangaroo.duration}ms`,
          transitionTimingFunction: ({ motion }) => motion.moveKangaroo.easing,
        }}
      >
        <div
          ref={innerContentRef}
          sx={contentStyles}
        >
          {children}
        </div>
      </div>
    </details>
  );
};

