import React from 'react';

import {
  ComponentProps, Icon, Label,
} from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';

interface ImagePlaceholderProps extends ComponentProps {}

export const ImagePlaceholder: React.FC<React.PropsWithChildren<ImagePlaceholderProps>> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={className}
      sx={{
        padding: 's',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'backgroundInformationlight',
      }}
    >
      <Icon
        name="Content/MediaImage"
        size={[ '48', '64' ]}
        color="actioninformationDefault"
        sx={{
          marginBottom: '3xs',
        }}
      />
      <Label
        as="p"
        variant={[ 'medium', 'large' ]}
        sx={{
          color: 'actioninformationDefault',
        }}
      >
        {t('hotelImageComingSoon')}
      </Label>
    </div>
  );
};
