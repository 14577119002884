import React, {
  ReactElement, ReactNode,
} from 'react';

import { ControlledTabs } from './ControlledTabs';
import { Tab, TabProps } from './Tab';
import { TabsProps } from './types';
import { UncontrolledTabs } from './UncontrolledTabs';

type TabsType = React.FC<React.PropsWithChildren<TabsProps>> &
{ Tab: React.FC<React.PropsWithChildren<TabProps>> };

export const Tabs: TabsType = (props) => {
  const { children, value } = props;

  const tabs = React.Children
    .toArray(children)
    .filter(Boolean) as ReactElement<TabProps & { children: ReactNode }>[];

  const isControlled = value !== undefined;

  return isControlled ? (
    <ControlledTabs
      activeTabId={value}
      tabs={tabs}
      {...props}
    />
  ) : (
    <UncontrolledTabs
      tabs={tabs}
      {...props}
    />
  );
};

Tabs.Tab = Tab;
