import { useTheme } from '@emotion/react';

import { useWindowSize } from '@Components';
import { BreakpointsPalette } from '@Themes';

type Breakpoint = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const comparator = (a: number, b: number) => a === b;

const getBreakpointIndex = (
  breakpoints: BreakpointsPalette,
  width: number,
) => breakpoints.findIndex((point) => parseInt(point, 10) > width);

/**
 * This function should be used only for client side.
 * On server side we should generate only one version.
 * It was added mostly for GridGalleryWithCarousel.
 * For checking isServer use next code:
 * const isServer = typeof window === 'undefined';
 */

const isServer = typeof window === 'undefined';

export const useBreakpoint = (): Breakpoint => {
  const { breakpoints } = useTheme();
  const { width } = useWindowSize();

  // If this is server we don't check values and return false.
  if (isServer) {
    return {
      isMobile: false,
      isTablet: false,
      isDesktop: false,
    };
  }

  const breakpointIndex = getBreakpointIndex(breakpoints, width);

  return {
    isMobile: comparator(breakpointIndex, 0),
    isTablet: comparator(breakpointIndex, 1),
    isDesktop: comparator(breakpointIndex, 2),
  };
};
