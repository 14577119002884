import React from 'react';

import { ChoiceControl, ChoiceControlProps } from '../ChoiceControl/ChoiceControl';

export type CheckProps = Pick<ChoiceControlProps, 'checked' | 'disabled' | 'onChange' | 'size' | 'data-id'>;

export const Check: React.FC<React.PropsWithChildren<CheckProps>> = (props) => (
  <ChoiceControl
    type="checkbox"
    iconOn="Toggles/CheckOn"
    iconOff="Toggles/CheckOff"
    {...props}
  />
);
