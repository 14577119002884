import React from 'react';

import { ComponentProps, Label } from '@Components';
import { LabelVariants } from '@Tokens';
import { Responsive } from '@Utils';

export interface TabHeaderProps extends ComponentProps {
  isActive?: boolean;
  labelVariant?: Responsive<LabelVariants>;
  label: string;
}

export const TabHeader: React.FC<React.PropsWithChildren<TabHeaderProps>> = ({
  isActive = false,
  labelVariant = 'small',
  label,
  className,
}) => (
  <Label
    className={className}
    variant={labelVariant}
    sx={{
      display: 'block',
      paddingBottom: '2xs',
      borderBottomWidth: 'activeStrokeWeight',
      borderStyle: isActive ? 'solid' : null,
      color: isActive ? 'textDefault' : 'textDimmedmedium',
      '&:hover': {
        color: isActive ? null : 'textDisabled',
        borderStyle: 'solid',
      },
    }}
  >
    {label}
  </Label>
);
