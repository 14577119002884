import React from 'react';

import { generatePanelId } from './generatePanelId';
import { generateTabId } from './generateTabId';
import { useTabsContext } from './TabsContext';
import { ComponentProps, TriggerButton } from '@Components';

export interface TabProps extends ComponentProps {
  id: string;
  header: React.ReactElement;
  // eslint-disable-next-line react/no-unused-prop-types
  lazy?: boolean;
}

export const Tab: React.FC<React.PropsWithChildren<TabProps>> = ({
  id,
  className,
  header,
}) => {
  const { activeTabId, changeTab, prefix } = useTabsContext();

  const isActive = activeTabId === id;

  const headerWithIsActive = (
    React.cloneElement(header, {
      isActive,
    }));

  return (
    <TriggerButton
      trackingAction={`${prefix}-tab`}
      eventLabel={id}
      role="tab"
      data-id={id}
      aria-selected={isActive}
      aria-controls={generatePanelId(prefix, id)}
      tabIndex={isActive ? 0 : -1}
      id={generateTabId(prefix, id)}
      onTrigger={() => changeTab(id)}
      className={className}
    >
      {headerWithIsActive}
    </TriggerButton>
  );
};
