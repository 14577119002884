import React from 'react';

import { Rating, RatingProps } from '../Rating';

export type GoogleRatingProps = Pick<RatingProps,
'className'
| 'data-id'
| 'reviewCount'
| 'rating'
| 'size'
| 'floating'
| 'variant'>;

export const GoogleRating: React.FC<React.PropsWithChildren<GoogleRatingProps>> = ({
  rating,
  reviewCount,
  'data-id': dataId = 'google-rating',
  className,
  size = 'default',
  floating = true,
  variant = 'inline',
}) => (
  <Rating
    rating={rating}
    reviewCount={reviewCount}
    data-id={dataId}
    className={className}
    size={size}
    floating={floating}
    variant={variant}
    brand="Google"
    brandIcon="Brands/GoogleFilled"
    filledIcon="Markers/GoogleStarRatingFilled"
    halfIcon="Markers/GoogleStarRatingHalf"
    emptyIcon="Markers/GoogleStarRating"
    ratingColor="iconGooglereview"
  />
);
