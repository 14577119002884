import React, { memo } from 'react';
import { SxStyleProp } from 'theme-ui';

import { ComponentProps, InlineList, Link } from '@Components';

const linkStyles: SxStyleProp = {
  fontSize: 'm',
  '&, &:visited': {
    color: 'brand',
  },
  '&:hover': {
    color: 'linkHover',
  },
};

// duplicated from sunrise/aurora-types.ts
type Breadcrumb = {
  id?: number | string | null;
  name: string;
  url?: string | null;
};

export interface BreadcrumbsProps extends ComponentProps {
  breadcrumbs: Breadcrumb[];
}

export const Breadcrumbs: React.FC<React.PropsWithChildren<BreadcrumbsProps>> = memo(({
  breadcrumbs,
  className,
}) => {
  const links = breadcrumbs.map(({ name, url }, i) => {
    const isLast = i === breadcrumbs.length - 1;

    return (!url || isLast)
      ? (
        <span
          key={i}
          sx={{ color: isLast ? 'inputDisabled' : 'textDefault' }}
        >
          {name}
        </span>
      )
      : (
        <Link
          key={i}
          href={url}
          sx={linkStyles}
        >
          {name}
        </Link>
      );
  });

  return (
    <InlineList
      className={className}
      items={links}
      separator="chevron"
    />
  );
});
