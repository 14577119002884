/* eslint-disable no-param-reassign */

import { useTheme } from '@emotion/react';
import { AnchorPointType } from 'components/AnchorPoint';
import { ComponentProps } from 'components/types';
import React, { MutableRefObject, useEffect, useRef } from 'react';

import { Label, TriggerButton } from '@Components';

export interface AnchorTabsBarProps extends Omit<ComponentProps, 'data-id'> {
  anchors: Pick<AnchorPointType, 'id' | 'title'>[];
  isSticky: boolean;
  onTrigger: (id: string) => void;
  currentLink: string;
  setHeight: (index: number) => void;
  containerRef?: MutableRefObject<HTMLDivElement>;
}

export const AnchorTabsBar: React.FC<React.PropsWithChildren<AnchorTabsBarProps>> = ({
  containerRef,
  id,
  isSticky,
  className,
  anchors,
  onTrigger,
  currentLink,
  setHeight,
}) => {
  const theme = useTheme();
  const tabBarRef = useRef<HTMLDivElement | null>(null);
  const tabBarPaddingRef = useRef<HTMLDivElement | null>(null);

  if (containerRef?.current) {
    containerRef.current.style.position = 'sticky';
    containerRef.current.style.top = '0px';
    containerRef.current.style.zIndex = String(theme.zIndices.stickyContent);
  }

  // Horizontally scroll the list of links so that the current is in view
  useEffect(() => {
    const tabBarElement = tabBarRef.current;
    const tabBarPaddingElement = tabBarPaddingRef.current;
    if (!tabBarElement || !tabBarPaddingElement) {
      return;
    }
    const linkElement = tabBarElement.querySelector(`[data-id=${CSS.escape(currentLink)}]`);
    // thisElement
    //   .current
    //   ?.querySelector(`[data-id=${CSS.escape(currentLink)}]`)
    //   ?.scrollIntoView();
    //
    // The above would be the obvious solution, but it makes the scrolling jerky.
    // Scrolling with the arrow keys are noticeably impacted.

    const linkBoundingBox = linkElement?.getBoundingClientRect();
    const tabBarBoundingBox = tabBarElement.getBoundingClientRect();

    const { paddingLeft, paddingRight } = window.getComputedStyle(tabBarPaddingElement);
    const paddingLeftI = parseInt(paddingLeft, 10);
    const paddingRightI = parseInt(paddingRight, 10);

    if (!linkBoundingBox || !tabBarBoundingBox) {
      return;
    }

    if (linkBoundingBox.left < tabBarBoundingBox.left + paddingLeftI) { // We're offscreen on the left
      tabBarElement.scrollTo({
        left:
        tabBarElement.scrollLeft + linkBoundingBox.left - tabBarBoundingBox.left - paddingLeftI,
        behavior: 'instant' as ScrollBehavior,
      });
    } else if (tabBarBoundingBox.right - paddingRightI < linkBoundingBox.right) { // We're offscreen on the right
      tabBarElement.scrollTo({
        left:
        tabBarElement.scrollLeft + linkBoundingBox.right - tabBarBoundingBox.right + paddingRightI,
        behavior: 'instant' as ScrollBehavior,
      });
    }
  }, [ currentLink ]);

  // return the height to the parent component
  useEffect(() => {
    const height = tabBarRef.current?.getBoundingClientRect().height;
    if (height !== undefined) {
      setHeight(height);
    }
  }, []);

  return (
    <div
      id={id}
      ref={tabBarRef}
      className={[ className, 'hide-scrollbars' ].filter(Boolean).join(' ')}
      data-id="AnchorTabsBar"
      sx={{
        ...(isSticky && { zIndex: 'stickyContent' }),
        borderBottomWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        borderColor: 'strokeLightneutral',
        overflowX: 'scroll',
        textAlign: 'center',
        position: 'sticky',
        top: 0,
        backgroundColor: 'backgroundWhite',
        marginBottom: '2xl',
      }}
    >
      <div
        ref={tabBarPaddingRef}
        sx={{
          display: 'inline-flex',
          gap: 'l',
          paddingX: ({ breakpointSizes }) => [ breakpointSizes.mobileMargin, breakpointSizes.tabletMargin, 'auto' ],
        }}
      >
        {anchors.map((anchor, index) => {
          const isSelected = anchor.id === currentLink;

          return (
            <TriggerButton
              data-id={anchor.id}
              key={index}
              sx={{
                paddingTop: 'l',
                paddingBottom: 's',
                borderBottomWidth: 'activeStrokeWeight',
                borderStyle: 'solid',
                whiteSpace: 'nowrap',
                ...(isSelected
                  ? {
                    borderColor: 'backgroundBlack',
                  }
                  : {
                    borderColor: 'transparent',
                    ':focus, :hover': {
                      borderColor: 'strokeDarkneutral',
                    },
                    ':active': {
                      borderColor: 'backgroundBlack',
                    },
                  }),
                '&:focus': {
                  outline: 'none',
                },
              }}
              onTrigger={() => {
                onTrigger(anchor.id);

                // Try to blur the active element to remove the focused state of the selected tab
                try {
                  (document.activeElement as HTMLDivElement).blur();
                  // eslint-disable-next-line no-empty
                } catch {}
              }}
              trackingAction="AnchorTabClick"
            >
              <Label variant={isSelected ? 'largebold' : 'large'}>
                {anchor.title}
              </Label>
            </TriggerButton>
          );
        })}
      </div>
    </div>
  );
};
