import React, { forwardRef, InputHTMLAttributes } from 'react';

import {
  InputFieldDecoration, InputFieldDecorationProps, inputFieldStyles,
} from './InputFieldDecoration';
import { useId } from '@Hooks';
import { IncrementSizes } from '@Tokens';

export interface InputFieldDateProps
  extends
  Pick<InputFieldDecorationProps, 'label' | 'message' | 'value' | 'state' | 'data-id'>,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'sx' | 'id' | 'value' | 'size' | 'type' | 'disabled'> {
  size: keyof Pick<IncrementSizes, '64' | '48'>;
}

/**
 * Input field for date values
 *
 * For compatibility with iPhones, use `onInput` rather than `onChange`.
 */
export const InputFieldDate = forwardRef<HTMLInputElement, InputFieldDateProps>(({
  label,
  message,
  size,
  state,
  value,
  onInput,
  'data-id': dataId,
  ...rest
}, ref) => {
  const id = useId();

  return (
    <InputFieldDecoration
      id={id}
      label={label}
      message={message}
      size={size}
      value={value}
      state={state}
      data-id={dataId}
    >
      <input
        ref={ref}
        type="date"
        value={value}
        max="9999-12-31"
        disabled={state === 'Disabled'}
        id={id}
        sx={inputFieldStyles({ state })}
        onInput={(e) => {
          /*
            Make iPhone Safari Reset button work.
            Without this, iPhone users have no way of clearing the field (other than upgrading to a
            better phone 😜).
            https://github.com/facebook/react/issues/8938
           */
          e.currentTarget.defaultValue = '';
          onInput?.(e);
        }}
        {...rest}
      />
    </InputFieldDecoration>
  );
});
