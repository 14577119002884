import React, { ReactElement } from 'react';

import { ActionButton, Paragraph, Title } from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';

export interface ModalHeaderBigProps {
  label: ReactElement | string;
  description: string;
  onClose: () => void;
  onBack?: () => void;
}

export const ModalHeaderBig: React.FC<React.PropsWithChildren<ModalHeaderBigProps>> = ({
  label,
  onClose,
  description,
  onBack,
}) => {
  const { t } = useTranslation();

  return (
    <header
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingX: 'xs',
        paddingY: '3xs',
        gap: '4xs',
        backgroundColor: 'backgroundWhite',
      }}
    >
      <div
        sx={{
          display: 'grid',
          justifyContent: 'space-between',
          paddingBottom: '3xs',
          gridAutoFlow: 'column',
          gridAutoColumns: '1fr',
        }}
      >
        {onBack
        && (
          <ActionButton
            icon="Navigation/ArrowBack"
            type="Icon"
            onClick={onBack}
            ariaLabel={t('back')}
            sx={{ justifySelf: 'start' }}
          />
        )}
        <ActionButton
          icon="Navigation/Close"
          type="Icon"
          onClick={onClose}
          sx={{ justifySelf: 'end' }}
          ariaLabel={t('close')}
        />
      </div>
      <Title
        as="h2"
        variant="xxsmall"
      >
        {label}
      </Title>
      <Paragraph
        variant="small"
        sx={{
          color: 'textDimmedheavy',
        }}
      >
        {description}
      </Paragraph>
    </header>
  );
};
