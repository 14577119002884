import {
  flip, offset, ReferenceType, safePolygon, shift, useDismiss, useFloating,
  useHover, useClick, useInteractions, Placement,
} from '@floating-ui/react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import React, { useState, useEffect, ReactNode } from 'react';

import { useClickOutside } from './useClickOutside';
import { Portal, TriggerButtonBehaviourProps } from '@Components';
import { useId } from '@Hooks';
import {
  fadeIn, fadeOut, motion, spacerSizes,
} from '@Tokens';

export interface PopoverProps {
  trigger?: 'click' | 'hover';
  /** The content displayed in the tooltip itself */
  content: ReactNode;
  /** Only used for demonstration in Storybook */
  alwaysOpened?: boolean;
  /* text to assist screenreader, necessary when the tooltip-trigger doesn't have a text */
  altText?: string;
  placement?: Placement;
}

export interface PopoverReturn {
  triggerProps: {
    ref: (node: ReferenceType) => void;
    'aria-describedby': string;
  } & TriggerButtonBehaviourProps;
  /** Render this as part of your component. It's a portal so won't disrupt your layout */
  portal: React.ReactNode | null;
}

export const usePopover = ({
  content,
  trigger = 'click',
  alwaysOpened = false,
  placement = 'top',
}: PopoverProps): PopoverReturn => {
  const [ isOpened, setIsOpened ] = useState(alwaysOpened);
  const onClose = () => setIsOpened(alwaysOpened || false);
  const {
    x,
    y,
    refs,
    strategy,
    refs: { reference: triggerRef, floating: popoverRef },
    context,
  } = useFloating({
    placement,
    middleware: [
      offset(spacerSizes['2xs']),
      flip(),
      shift(),
    ],
    open: isOpened,
    onOpenChange: setIsOpened,
  });

  useClickOutside({
    ref: [ popoverRef, triggerRef as React.MutableRefObject<HTMLElement> ],
    isActive: isOpened && trigger === 'click',
    onClick: onClose,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context),
    trigger === 'click'
      ? useClick(context)
      : useHover(context, {
        handleClose: safePolygon({
          blockPointerEvents: false,
        }),
      }),
  ]);

  const id = useId();

  useEffect(() => {
    if (triggerRef.current && isOpened) {
      disableBodyScroll(triggerRef.current as HTMLElement, {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => el !== triggerRef.current,
      });
    } else {
      clearAllBodyScrollLocks();
    }
  }, [ triggerRef.current, isOpened ]);

  return {
    triggerProps: {
      ref: refs.setReference,
      'aria-describedby': id,
      ...getReferenceProps(),
      onClick: () => setIsOpened(!isOpened),
    },
    portal: isOpened ? (
      <Portal>
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          sx={{
            position: strategy,
            backgroundColor: 'backgroundWhite',
            borderRadius: '8',
            width: 'max-content',
            maxWidth: [ '80vw', '400px' ],
            zIndex: 'tooltip',
            boxShadow: 'elevationFixed',
            top: y,
            left: x,
            animation: `${isOpened ? fadeIn : fadeOut} ${motion.fadeinoutCheetah.duration}ms ${motion.fadeinoutCheetah.easing}`,
          }}
        >
          {content}
        </div>
      </Portal>
    ) : null,
  };
};
